export function GetALLQuery() {
  return new Promise(async (resolve) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/contact/query`, {
        method: "GET",
        headers: { "content-type": "application/json" },
        credentials: 'include'
      });
      const data = await response.json();
      resolve({ data });
    } catch (err) {
      console.log(err);
    }
  });
}

export function GetALLNewsletter() {
  return new Promise(async (resolve) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/contact/newsletter`, {
        method: "GET",
        headers: { "content-type": "application/json" },
        credentials: 'include'
      });
      const data = await response.json();
      resolve({ data });
    } catch (err) {
      console.log(err);
    }
  });
}

export function DeleteQuery(userdata) {
  return new Promise(async (resolve) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/contact/query`, {
        method: "DELETE",
        body: JSON.stringify(userdata),
        headers: { "content-type": "application/json" },
        credentials: 'include'
      });
      const data = await response.json();
      resolve({ data });
    } catch (err) {
      console.log(err);
    }
  });
}

export function DeleteNewsletter(userdata) {
  return new Promise(async (resolve) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/contact/newsletter`, {
        method: "DELETE",
        body: JSON.stringify(userdata),
        headers: { "content-type": "application/json" },
        credentials: 'include'
      });
      const data = await response.json();
      resolve({ data });
    } catch (err) {
      console.log(err);
    }
  });
}
