import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { DeleteNewsletter, DeleteQuery, GetALLNewsletter, GetALLQuery } from "./QueryAPI"

const initialState = {
   Status: "idl",
   Queries:[],
   newsletters:[],
   error:''

}

export const GetAllQueriesAsync = createAsyncThunk(
   'query/all',
   async ()=>{
      const response = await GetALLQuery();
      return response.data;
   }
)

export const GetAllNewsLetterAsync = createAsyncThunk(
   'query/newsletter', async ()=>{
      const response = await GetALLNewsletter();
      return response.data;
   }
)

export const DeleteQueryAsync = createAsyncThunk('query/delete', async (userdata)=>{
   const response = await DeleteQuery(userdata);
   return response.data;
})

export const DeleteNewsLetterAsync = createAsyncThunk('query/newsletter/delete', async (userdata)=>{
   const response = await DeleteNewsletter(userdata);
   return response.data;
})


const QuerySlice = createSlice({
   name:'query',
   initialState,
   reducers:{},
   extraReducers:(builder)=>{
      builder
      .addCase(GetAllQueriesAsync.pending,(state)=>{
         state.Status = 'pending';
      })
      .addCase(GetAllQueriesAsync.fulfilled,(state,actions)=>{
         state.Status = 'idl';
         state.Queries = actions.payload;
      })
      .addCase(GetAllNewsLetterAsync.pending,(state)=>{
         state.Status = 'pending';
      })
      .addCase(GetAllNewsLetterAsync.fulfilled,(state,actions)=>{
         state.Status = 'idl';
         state.newsletters = actions.payload;
      })
      .addCase(DeleteQueryAsync.pending,(state)=>{
         state.Status = 'pending';
      })
      .addCase(DeleteQueryAsync.fulfilled,(state,actions)=>{
         state.Status = 'idl';
      })
      .addCase(DeleteNewsLetterAsync.pending,(state)=>{
         state.Status = 'pending';
      })
      .addCase(DeleteNewsLetterAsync.fulfilled,(state,actions)=>{
         state.Status = 'idl';
      })
   }
})


export const AllQueryDetails = (state)=> state.query.Queries;

export const AllNewsLetters = (state)=> state.query.newsletters;

export default QuerySlice.reducer;