export function CreateAdmin(userdata){
  return new Promise (async (resolve)=>{
    console.log('Create Admin API Called');
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/admin`,{
      method:'POST',
      body:JSON.stringify(userdata),
      headers:{'content-type':'application/json'},
      credentials: 'include'
    })

    const data = await response.json();
    resolve({data})
    
  })
}


export function Login(userdata) {
  return new Promise(async (resolve) => {
   console.log('Log in API called')
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/login`, {
      method: "POST",
      body: JSON.stringify(userdata),
      headers: { "content-type": "application/json" },
      credentials: 'include'
    });

    const data = await response.json();
    resolve({ data });
  });
}


export async function checkAuth() {
  try {
    const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/user/check`, {
      method: "POST",
      body:JSON.stringify(),
      headers:{'content-type':'application/json'},
      credentials: 'include'
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Failed to authenticate");
    }

    const data = await response.json();
    return { data };
  } catch (error) {
    throw new Error(
      error.message || "Authentication failed due to an unexpected error"
    );
  }
}

