

import React, { useEffect, useState } from "react";
import VideoList from "./videolistmodel";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { DeleteViodeoAsync } from "../../Features/Videos/VideosSlice";

export default function Videos() {
  const location = useLocation();
  const { Id } = location.state;
  const [ videos,setVideos] = useState([])
  const dispatch = useDispatch();

  const handleNavigate = () => {
    // For external links, use window.location.href
    window.location.href = "https://mail.google.com/";

    // Alternatively, open in a new tab:
    // window.open("https://www.example.com", "_blank");
  };

  const handleUpdate = (id) => {
    alert(`Update video with ID: ${id}`);
  };

  const handleDelete = (id) => {
      dispatch(
        DeleteViodeoAsync(id)
      )
      handleNavigate()
  };

  const data = { Id: Id };

  useEffect(() => {
    const fetchCourseVideos = async (data) => {
      try {
        const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/class/videos`, {
          method: "POST",
          body: JSON.stringify(data),
          headers: { "content-type": "application/json" },
        });
        const responsedata = await response.json();
        setVideos(responsedata);
        console.log(responsedata);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCourseVideos(data);
  }, []);

  // if (!videos) {
  //       return (
  //         <div className="main">
  //           <ReactLoading type="balls" color="#0000FF" height={100} width={50} />

  //         </div>
  //       );
  //     }

  return (
    <div>
      <VideoList
        videos={videos}
        onUpdate={handleUpdate}
        onDelete={handleDelete}
      />
    </div>
  );
}
