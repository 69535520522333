export function AddCourse(userdata) {
  return new Promise(async (resolve) => {
    try{
    const response = await fetch(`https://api.bhokaltrader.com/course`,  {
      method:'POST',
      body:userdata,
      credentials: 'include'
    });
    const data = await response.json();
    resolve({ data });
  }
  catch(err){
    console.log("Error in uploading",err)
  }
  });
}



export function getAllCources() {
  return new Promise(async (resolve) => {
   console.log('Get All Courses API Called')
    const response = await fetch(`https://api.bhokaltrader.com/course`, {
      method: "GET",
      body: JSON.stringify(),
      headers: { "content-type": "application/json" },
      credentials: 'include'
    });
    const data = await response.json();
    resolve({ data });
  });
}
