import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AddVideos, DeleteVideos } from "./VideosAPI";

const initialState = {
   Status: 'idle', // Fixed typo: 'idl' to 'idle'
   video: '',
   error: '',
   delete:''
};

// Async thunk for adding video
export const AddVideoAsync = createAsyncThunk(
   'video/add',
   async (userdata, { rejectWithValue }) => {
      try {
         console.log("Add Video API called", userdata);
         const response = await AddVideos(userdata);
         return response.data;
      } catch (error) {
         // Return the error message for rejected state
         return rejectWithValue(error.message || 'Failed to add video');
      }
   }
);

export const DeleteViodeoAsync = createAsyncThunk(
   'video/delete',
   async (userdata,{rejectWithValue})=>{
      try{
         const response = await DeleteVideos(userdata)
         return response.data;

      }
      catch(err){
         console.log(err);
         return rejectWithValue(err.message || 'Failed to Delete Video')
         
      }
   }
)

const VideoSlice = createSlice({
   name: "video",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      builder
         .addCase(AddVideoAsync.pending, (state) => {
            state.Status = 'pending';
         })
         .addCase(AddVideoAsync.fulfilled, (state, actions) => {
            state.Status = 'idle';
            state.video = actions.payload;
            state.error = ''; // Reset error on successful addition
         })
         .addCase(AddVideoAsync.rejected, (state, action) => {
            state.Status = 'idle';
            state.error = action.payload; // Set error message if request fails
         })
         .addCase(DeleteViodeoAsync.pending,(state)=>{
            state.Status = 'pending';
         })
         .addCase(DeleteViodeoAsync.fulfilled ,(state,actions)=>{
            state.Status = 'idl';
            state.delete = actions.payload;
         });
   }
});

// Selector for accessing the video data
export const Videoadded = (state) => state.video.video;

// Selector for accessing the status of the add video operation
export const VideoStatus = (state) => state.video.Status;

// Selector for accessing any errors
export const VideoError = (state) => state.video.error;

export const DeleteVideoData = (state)=>state.video.delete;

export default VideoSlice.reducer;
