import React from "react";
import "../../Css/videolist.css";

const VideoList = ({ videos, onUpdate, onDelete }) => {
  return (
    <div className="video-list-container">
      <h1>Video List</h1>
      <div className="video-list">
        {videos.map((video, index) => (
          <div className="video-card" key={video.id || index}>
            <div className="video-info">
              <p>
                <strong>Number:</strong> {index + 1}
              </p>
              <p>
                <strong>Name:</strong> {video.Video_Title}
              </p>
              <p>
                <strong>Duration:</strong> {video.duration}  hours
              </p>
            </div>
            <div className="video-actions">
              {/* <button
                className="update-btn"
                onClick={() => onUpdate(video._id)}
              >
                Update
              </button> */}
              <button
                className="delete-btn"
                onClick={() => onDelete(video._id)}
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default VideoList;
