import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LoginPage from "../src/Components/Login";
import Sidebar from "../src/Components/Sidebar";
import AddCourse from "./Components/ManageBatches";
import AddVideo from "./Components/ManageVideos";
import AddUser from "../src/Components/Userdetails";
import Header from "./Components/Header";
import Footer from "./Components/Fotter";
import Course from "./Components/Course";
import Users from "./Components/Users";
import DashboardOverview from "./Components/Dashboard";
import { useDispatch, useSelector } from "react-redux";
import { checkAuthAsync, islogged, LoginedInfo } from "./Features/Auth/AuthSlice";
import Videos from "./Components/subComponet/Videos";
import Query from "./Components/Query";
import NewsLetter from "./Components/newsletter";
import Signup from "./Components/Signup";


const App = () => {
  const isLoggedIn = useSelector(islogged);
  const logininfo = useSelector(LoginedInfo);
  const dispatch = useDispatch();

 useEffect(()=>{
      dispatch(
        checkAuthAsync()
      )
 },[])

  return (
    <Router>
      <div>
        {logininfo.role === 'admin'  && <Header />}
        {logininfo.role === 'admin' && <Sidebar />}
        <div className="content">
          <Routes>
            {/* If not logged in, show login page */}
            {logininfo.role != 'admin'  ? (
            <> <Route path="/" element={<LoginPage />} />
              <Route path="/singup" element={<Signup/>} /></> 
            ) : (
              <>
                {/* If logged in, show the dashboard and other routes */}
              {  logininfo.role === 'admin' && <Route path="/" element={<DashboardOverview />} />}
                
               { logininfo.role === 'admin' && <Route path="/dashboard/add-course" element={<AddCourse />} />}
                {logininfo.role === 'admin' &&<Route path="/dashboard/add-video" element={<AddVideo />} />}
                {logininfo.role === 'admin' &&<Route path="/dashboard/add-users" element={<AddUser />} />}
                {logininfo.role === 'admin' &&<Route path="/dashboard/courses" element={<Course />} />}
               {logininfo.role === 'admin' && <Route path="/dashboard/students" element={<Users />} />}
                {logininfo.role === 'admin' &&<Route path="/dashboard/courses/videos" element={<Videos />} />}
                {logininfo.role === 'admin' &&<Route path="/dashboard/query" element={<Query/>} />}
                {logininfo.role === 'admin' &&<Route path="/dashboard/newsletter" element={<NewsLetter/>} />}
              </>
            )}
          </Routes>
        </div>
        {isLoggedIn && <Footer />}
      </div>
    </Router>
  );
};

export default App;
