import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AllCourses } from "../Features/Course/CourseSlice";
import { AddedStudent, AddStudentAsync } from "../Features/Users/UserSlice";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [errors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [course_id, setCourseId] = useState("");
  const allCourse = useSelector(AllCourses); // Get all courses from the Redux store

  const [courses, setCourses] = useState([]);

  // Fetch course IDs once all courses are available
  useEffect(() => {
    if (allCourse?.cousres) {
      setCourses(
        Object.values(allCourse.cousres).map((cousres) => cousres.Course_No)
      ); // Extract course IDs
    }
  }, [allCourse]);

  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    courseId: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({ ...user, [name]: value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Dispatch action to add student with user data

    const id = allCourse.cousres
      .flatMap((arr) => arr)
      .find((item) => item.Course_No == user.courseId)?._id;

    const response = dispatch(
      AddStudentAsync({
        Name: user.name,
        email: user.email,
        password: user.password,
        Batch_No: id,
      })
    );
    if (response.payload?.email) {
      // Assuming `response.payload.success` indicates success
      setSuccessMessage("Course added successfully!");
      setLoading(false);
    } else {
      // Handle failure
      setErrors({ api: "Failed to add course. Please try again." });
      setLoading(false);
    }
  };

  return (
    <div className="content">
      <h2>Add Students</h2>
      <form className="form" onSubmit={handleSubmit}>
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={user.name}
          onChange={handleChange}
          required
        />
        <input
          type="email"
          name="email"
          placeholder="Email Address"
          value={user.email}
          onChange={handleChange}
          required
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={user.password}
          onChange={handleChange}
          required
        />
        <select
          name="courseId"
          value={user.courseId}
          onChange={handleChange}
          required
        >
          <option value="">Select Course ID</option>
          {courses.length > 0 ? (
            courses.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))
          ) : (
            <option>Loading Courses...</option> // Display loading state if courses are not loaded yet
          )}
        </select>

        {loading && <div className="loading">Adding Student...</div>}
        {successMessage && (
          <div className="success">Student added successfully!</div>
        )}
        {errors.submit && <div className="error">{errors.submit}</div>}

        <button type="submit">Add User</button>
      </form>
    </div>
  );
};

export default AddUser;
