import React, { useState } from "react";
import '../Css/Query.css';
import { useDispatch, useSelector } from "react-redux";
import { AllQueryDetails, DeleteQueryAsync } from "../Features/Query/QuerySlice";

const Query = () => {
  const queries = useSelector(AllQueryDetails)
  const dispatch = useDispatch();

  const handleDelete = (id) => {
      dispatch(
         DeleteQueryAsync({_id:id})
      )

  };

  if(!queries){
   return (
      <div style={{display:'flex',justifyContent:'center',alignItems:'center', width:'100%'}}>Loading</div>
   )
  }

  return ( <div className="container">
      <h1>User Queries</h1>
      <div className="query-list">
        {queries.map((query) => (
          <div key={query._id} className="query-card">
            <div className="query-details">
              <p><strong>Name:</strong> {query.Name}</p>
              <p><strong>Email:</strong> {query.email}</p>
              <p><strong>Message:</strong> {query.message}</p>
            </div>
            <button onClick={() => handleDelete(query._id)} className="delete-btn">
              Delete
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Query;
