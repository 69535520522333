import React, { useState } from "react";
import "../Css/Newsletter.css";
import { useDispatch, useSelector } from "react-redux";
import { AllNewsLetters, DeleteNewsLetterAsync } from "../Features/Query/QuerySlice";

const NewsLetter = () => {
  const emails = useSelector(AllNewsLetters);
  const dispatch = useDispatch();

  const handleDelete = (_id) => {
    dispatch(
      DeleteNewsLetterAsync({_id:_id})
    )
  };

  if(!emails || emails === null){
   return (
      <div style={{display:'flex', width:'100%',justifyContent:'center',alignItems:'center'}}>
         <p>Loading</p>
      </div>
   )
  }

  return (
    <div className="container">
      <h1>Email List</h1>
      <ul className="email-list">
        {emails.map((item) => (
          <li key={item._id} className="email-item">
            <span className="email-text">{item.email}</span>
            <button onClick={() => handleDelete(item._id)} className="delete-btn">
              Delete
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default NewsLetter;
