import React, { useState } from "react";
import "../../Css/usercard.css";
import {useDispatch} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import { DeleteViodeoAsync } from "../../Features/Videos/VideosSlice";
import { DeleteStudentAsync } from "../../Features/Users/UserSlice";





const PopupModal = ({ message, onClose, onConfirm }) => {
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{message}</h2>
        <div className="modal-actions">
          <button className="confirm-btn" onClick={onConfirm}>
            Yes
          </button>
          <button className="cancel-btn" onClick={onClose}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};


const StudentCard = ({ student }) => {
  const [showModal, setShowModal] = useState(false);
  const [popUpMessage,setPopUpMessage] = useState('');
  
  console.log(student);
  const dispatch = useDispatch();
  const handleNavigate = () => {
    // For external links, use window.location.href
    window.location.href = "https://mail.google.com/";

    // Alternatively, open in a new tab:
    // window.open("https://www.example.com", "_blank");
  };

  const handleDeleteClick = () => {
    setPopUpMessage('"Do you really want to delete the student?"')
    setShowModal(true);
  };

  // Close the modal
  const handleCloseModal = () => {

    setShowModal(false);
  };

  // Confirm delete action
  const handleConfirmDelete = async () => {
    console.log("Student Deleted!");
  
    try {
      // Dispatch the delete action and wait for its response
      const response = await dispatch(DeleteStudentAsync(student));
  
      // Check if the API response is successful

      handleNavigate()
      
     
    } catch (error) {
      console.error("An unexpected error occurred:", error);
      alert("Something went wrong. Please try again later.");
    }
  }

  return (
    <div className="user-profile-card">
      <div className="profile-info">
        <h3 className="username">{student.Name}</h3>
        <p className="email">Email: {student.email}</p>

        <p className="course-id">Course ID: {student.Batch_No.Course_No}</p>
      </div>
      <div className="actions">
        {/* <button className="update-btn" >
          Update
        </button> */}
        <button onClick={handleDeleteClick} className="delete-btn">
          Delete
        </button>
        {showModal && (

        <PopupModal
          message={popUpMessage}
          onClose={ handleCloseModal } 
          onConfirm={handleConfirmDelete}
        />
      )}
      </div>
    </div>
  );
};





export default StudentCard;
